import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Outlet,
    Route,
    RouterProvider,
    Routes,
    ScrollRestoration,
    useLocation,
    useNavigate,
  } from "react-router-dom";
import { UniversityUpdatesScreen } from "./univeristy-updates/UniversityUpdates";
import { LoginScreen } from "./Login";
import { useFirebaseAuth } from "./contexts/FirebaseAuthContext";
import { FaGraduationCap, FaEllipsisVertical, FaSistrix, FaFilter, FaSort, FaArrowsSpin } from "react-icons/fa6";
import {
    AppOutline,
    MessageOutline,
    UnorderedListOutline,
    UserOutline,
    SearchOutline,
    MoreOutline
  } from 'antd-mobile-icons'
import  FirebaseLoginScreen from './profile/Login';
import { FC, useEffect, useState } from "react";
import { useTheme } from "./contexts/ThemeContext";
import { TabBar } from "antd-mobile";
import { ProfileScreen } from "./profile/Profile";
import { SettingsScreen } from "./profile/Settings";
import logger from "./logger";
import { UniversityUpdateSearchScreen } from "./univeristy-updates/UniversityUpdateSearch";
import { UniversityUpdateSubmissionScreen } from "./univeristy-updates/UniversityUpdateSubmission";
import { UniversityProfileScreen } from "./university-profile/UniversityProfile";
import { UniversityProfilesScreen } from "./university-profile/UniversityProfiles";
import { UserProvider } from "./contexts/UserContext";
import { UniversityUpdatesProvider } from "./contexts/UniversityUpdatesContext";
import UniversityProfilesSearchScreen from "./university-profile/UniversityProfilesSearch";
import UniversityProfileFilterScreen from "./university-profile/UniversityProfileFilter";


const MyBottomTabBar: FC = () => {
    const location = useLocation()
    const { pathname } = location
    const mainRoute = pathname.split('/')[1];
    const navigate = useNavigate()
    const {theme} = useTheme()
  
    // const [activeKey, setActiveKey] = useState('university-update')
  
    const tabs = [
      
      {
        key: 'profile',
        title: 'الحساب',
        icon: <UserOutline />,
      },
      {
        key: 'university-update',
        title: 'تحديثات القبول',
        icon: <FaArrowsSpin />
      },
      {
        key: 'university-profiles',
        title: 'الجامعات',
        icon: <FaGraduationCap />
      }
      
      
    ]
    return (
        <TabBar
          activeKey={mainRoute}
          style={{position: 'sticky',  height: "50px",  bottom: 0, width: "100%", 
            color: 'red',   
            backgroundColor: theme.colors.background,
            zIndex: 2 }}
          onChange={ (key) => { navigate(key); }}
          >
          {tabs.map(item => {
            return(
              <TabBar.Item key={item.key} icon={item.icon} title={item.title}  />
          )})}
        </TabBar>
    )
  }
  


const ParentLayout = () => {
    return (
      <div style={{direction: 'rtl', paddingLeft: "10px", paddingRight: "10px", height: '100vh', width: '100hv',  display: 'flex', justifyContent: 'center'}} >
        <div style={{maxWidth: '700px', width: '100%'}}> 
          <div style={{ minHeight: '100%',}}>
            <Outlet  />
          </div>
            <ScrollRestoration getKey={(location) => location.pathname} />
          <MyBottomTabBar />
        </div>   
      </div>
    );
  };




const publicRoutes = 
    createRoutesFromElements(
        <>
            <Route path="signin" element={<FirebaseLoginScreen />} />
            <Route path="*" element={<Navigate to="/signin" replace />} />

        </>
        
    )

const universityProfileRoute = 
  <Route path='university-profile' >
    <Route index element={ <UniversityProfileScreen />} />
    <Route path=':universityNameAr' element={<UniversityProfileScreen />}/>
  </Route>
const protectedRoutes =
    createRoutesFromElements(
        <Route element={<ParentLayout />}> 
            <Route path='profile'  >
                <Route index element={<ProfileScreen />}  />
                <Route path='settings' element={<SettingsScreen />} />
                {universityProfileRoute}
            </Route>
            <Route path='university-update'  >
                <Route index element={ <UniversityUpdatesScreen />} />
                <Route path='search' >
                  <Route index element={<UniversityUpdateSearchScreen />} />
                  {universityProfileRoute}
                </Route>
                {universityProfileRoute}
                <Route path='submission/:universityUpdateId?' element={<UniversityUpdateSubmissionScreen />}/>
            </Route>
            <Route path='university-profiles' >
              <Route index element={ <UniversityProfilesScreen />} />
              <Route path='search' >
                  <Route index element={<UniversityProfilesSearchScreen />} />
                  {universityProfileRoute}
                  {/* <Route path='filter' element={<UniversityProfileFilterScreen />}/> */}
              </Route>
              {universityProfileRoute}
            </Route>

            <Route path="*" element={<Navigate to="university-update" replace />} />
        </Route>
    )   


const ProtectedRouter = () => {
  const router = createBrowserRouter(protectedRoutes);

  return (
    <UserProvider>
      <UniversityUpdatesProvider>
          <RouterProvider router={router} />
      </UniversityUpdatesProvider>
    </UserProvider>
  )
}

const PublicRouter = () => {
  const router = createBrowserRouter(publicRoutes);
  return (
    <RouterProvider router={router} />
  )
}




const Router = () => {
  const { currentUser, loading } = useFirebaseAuth();

  if (loading) {
    return <></>; // Show an empty fragment while loading
  }

  return currentUser ? <ProtectedRouter /> : <PublicRouter />;
};


  

  export default Router