import { MyCard, MyNavbar, MySpace } from "../MyDesign"
import { Action } from 'antd-mobile/es/components/popover'
import { Label, UniversityProfile } from "./UniversityProfile"
import { FaLink } from "react-icons/fa6"
import { useImageQuery, useMyGeneralQuery } from "../api"
import logger from "../logger"
import { useNavigate } from "react-router-dom"
import { FixedSizeList, ListChildComponentProps, ListOnScrollProps } from 'react-window';
import { Divider, Skeleton } from "antd-mobile"
import { useCallback, useEffect, useRef, useState } from "react"
import { FlagIcon, FlagIconCode } from "react-flag-kit"
import { useTheme } from "../contexts/ThemeContext"
import Slider from 'react-slick';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { format } from "date-fns"



const actions: Action[] = [
    // { key: 'filter', text: 'فلتر' },
    // { key: 'sort', text: 'نوع' },
]


const UniversityProfileCard = ({universityProfile} : {universityProfile?: UniversityProfile}) => {
    const navigate = useNavigate()
    const {theme}   = useTheme()

    const {data} = useImageQuery(`/university-profiles/public/university-logos/${universityProfile?.logoFilename}`, `/university-profiles/public/university-logos/${universityProfile?.logoFilename}`)
    const imageUrl = data ? URL.createObjectURL(data) : ''

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
      };
    
   


    const title =
         <div style={{display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', width: '100%'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', gap: '10px'}}>
                {/* <img src={imageUrl} alt="Description of the image" width="40" height="40" style={{borderRadius: '10px'}}
                    onClick={ () => navigate(`university-profile/${universityProfile?.universityNameAr}`)}
                    /> */}
                <LazyLoadImage src={imageUrl} alt="Description of the image" width="40" height="40" style={{borderRadius: '10px'}}
                    onClick={ () => navigate(`university-profile/${universityProfile?.universityNameAr}`)}
                    />

                <div style={{fontSize: "18px"}} onClick={ () => navigate(`university-profile/${universityProfile?.universityNameAr}`)}>
                    {universityProfile?.universityNameAr}
                </div>
                <FlagIcon size={14} code={universityProfile?.countryCode as FlagIconCode} style={{borderRadius: '14px'}} />
            </div>
            <div style={{fontSize: "18px", backgroundColor: theme.colors.surfaceSecondary,  height: '30px', paddingLeft: '5px', paddingRight: '5px',  borderRadius: 8, boxShadow: theme.shadows.surfaceSecondary, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={ () => navigate(`university-profile/${universityProfile?.universityNameAr}`)}>
                {`#${Number.parseInt(universityProfile?.rank?.toString() as string)}`}
            </div>  
        </div>

    return(
        <MyCard style={{direction: 'rtl', height: '200px', marginBottom: '20px'}}>  
            {title}   
             <Divider />
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center', gridRow: 2 }}>
                <div style={{ display: 'flex',maxWidth: '500px', width: '100%', flexDirection: 'row-reverse', justifyContent: 'center', gap: '10%' }}>
                    <Label labelKey={"ايلتس"} labelValue={universityProfile?.minimumIeltsScore} />
                    <Label labelKey={"توفل"} labelValue={universityProfile?.minimumToeflScore} />
                    <Label labelKey={"سات"} labelValue={universityProfile?.satRequirement} />
                </div>
                <div style={{ display: 'flex',maxWidth: '500px', width: '100%', flexDirection: 'row-reverse', justifyContent: 'center', gap: '10%' }}>
                    <Label labelKey={"عدد التوصيات"} labelValue={universityProfile?.numberOfRecommendationLetters} />
                    <Label labelKey={"الرسوم"} labelValue={universityProfile?.applicationFee} />
                    <Label 
                            labelKey={"نهاية التقديم"} 
                            labelValue={
                                universityProfile?.deadlines === 'rolling admissions' 
                                    ? 'القبول المتداول' 
                                    : Array.isArray(universityProfile?.deadlines) && universityProfile.deadlines.length > 0 
                                        ? format(universityProfile.deadlines[0], 'MMMM dd, yyyy') 
                                        : 'غير محدد'
                            } 
                        />



                </div>
                <MySpace />
            </div>
        </MyCard>
    )
}


const UniversityProfilesList = ({ universityProfiles } : any) => {
    const [scrollOffset, setScrollOffset] = useState(Number.parseInt(sessionStorage.getItem('scrollOffset') || '0'));
    const listRef = useRef<FixedSizeList>(null);

    const {theme} = useTheme()

    useEffect(() => {
        // Retrieve saved scroll offset from sessionStorage
        const savedScrollOffset = sessionStorage.getItem('scrollOffset');
        if (savedScrollOffset) {
            setScrollOffset(Number(savedScrollOffset));
        }
    }, []);

    useEffect(() => {
        // Scroll to the saved position once the component mounts and the listRef is set
        if (listRef.current) {
            listRef.current.scrollTo(scrollOffset);
        }
    }, [scrollOffset]);

    const handleScroll = ({ scrollOffset }: { scrollOffset: number }) => {
        sessionStorage.setItem('scrollOffset', scrollOffset.toString());
    };

    const Row: React.FC<ListChildComponentProps> = ({ index, style }) => (
        <div key={universityProfiles?.[index]["universityNameAr"]} style={style} >
            <UniversityProfileCard universityProfile={universityProfiles?.[index]} />
        </div>
    );

    return (
        <FixedSizeList
            ref={listRef}
            overscanCount={50}
            height={window.innerHeight}
            width="100%"
            itemSize={220}
            itemCount={universityProfiles?.length || 0}
            onScroll={({ scrollOffset }) => handleScroll({ scrollOffset })}
            initialScrollOffset={scrollOffset}
        >
            {Row}
        </FixedSizeList>
    );
};

const UniversityProfileCardSkeleton = () => {
    return (
        <div style={{  display: "flex"}}>
            <Skeleton animated style={{width: "100%", minHeight: '200px', borderRadius: '14px'}}/>
        </div>
    )
}

export const UniversityProfileCardSkeletonList = () => {
    return (
        <MySpace direction="vertical">
            { Array.from({ length: 10 }).map((val) => 
                <UniversityProfileCardSkeleton />) }
        </MySpace>
    )
}

const UniversityProfilesScreen: React.FC = () => {
    const { data: universityProfiles, isLoading } = useMyGeneralQuery<UniversityProfile[]>([`/university-profiles/`], `/university-profiles/`);
    return ( 
        <>
            <MyNavbar navigateTo={"search"} actions={actions} leftTitle={"ترتيب الجامعات"} style={{marginBottom: '0px' }}/>
            { isLoading ?
                <UniversityProfileCardSkeletonList /> :
                <UniversityProfilesList universityProfiles={universityProfiles} /> }
        </>
    );
};


export {UniversityProfilesScreen, UniversityProfilesList}