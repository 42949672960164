// axiosInstance.ts
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions, QueryKey, UseMutationOptions, UseMutationResult, MutationFunction, useMutation,
  useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import { useAuth } from './contexts/AuthContext';




const useAxios = () => {
  // const { getAccessTokenSilently } = useMyAuth0();
  const {currentUser} = useAuth()
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  axiosInstance.interceptors.request.use(async (config) => {
    if(currentUser){
      const token = await currentUser.getIdToken();
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  return axiosInstance;
};



const useMyGeneralQuery = <TData = unknown, TError = unknown>(
  key: QueryKey,
  endpoint: string,
  params?: Record<string, any>,
  options?: UseQueryOptions<TData, TError>
) => {
  const axios = useAxios();

  return useQuery<TData, TError>(
    key,
    async () => {
      const { data } = await axios.get(endpoint, { params });
      return data;
    },
    options
  );
};



export interface PaginatedResponse<T> {
  data: T[];        // Adjust 'data' to match the property your API returns for items
  hasNextPage: boolean
}


export const useMyGeneralInfiniteQuery = <TData = unknown, TError = unknown>(
  key: QueryKey,
  endpoint: string,
  initialParams?: Record<string, any>,
  options?: UseInfiniteQueryOptions<PaginatedResponse<TData>, TError>
) => {
  const axios = useAxios();

  return useInfiniteQuery<PaginatedResponse<TData>, TError>(
    key,
    async ({ pageParam = 1 }) => {
      const { data } = await axios.get(endpoint, {
        params: { ...initialParams, page: pageParam, limit: 20 }, // Adjust limit as needed
      });
      return data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        // Ensure nextPage is based on the current page, and totalPages from lastPage
        if (lastPage.hasNextPage) {
          return pages.length + 1;
        }
        return undefined;

      },
      ...options,
    }
  );
};





export const useImageQuery = (key: string, endpoint: string, params?: Record<string, any>, options?: UseQueryOptions<Blob>) => {
  const axios = useAxios();

  return useQuery<Blob>(
    key,
    async () => {
      const response = await axios.get(endpoint, {
        params,
        responseType: 'blob' // Ensure response is treated as a Blob
      });
      return response.data;
    },
    options
  );
};




type MutationFn<TData, TVariables> = (variables: TVariables) => Promise<AxiosResponse<TData>>;


const useMyGeneralMutationDeprecated = <TData = unknown, TError = AxiosError, TVariables = any>(
  endpoint: string,
  options?: UseMutationOptions<AxiosResponse<TData>, TError, TVariables>
): UseMutationResult<AxiosResponse<TData>, TError, TVariables> => {
  const axios = useAxios();

  const mutationFn: MutationFn<TData, TVariables> = async (variables: TVariables) => {
    const { data } = await axios.post(endpoint, variables);
    return data;
  };

  return useMutation<AxiosResponse<TData>, TError, TVariables>(mutationFn, options);
};


type HttpMethod = 'post' | 'put' | 'delete';

const useMyGeneralMutation = <TData = unknown, TError = AxiosError, TVariables = any>(
  endpoint: string,
  method: HttpMethod = 'post',
  options?: UseMutationOptions<AxiosResponse<TData>, TError, TVariables>
): UseMutationResult<AxiosResponse<TData>, TError, TVariables> => {
  const axios = useAxios();

  const mutationFn: MutationFunction<AxiosResponse<TData>, TVariables> = async (variables: TVariables) => {
    let response;
    switch (method) {
      case 'post':
        response = await axios.post(endpoint, variables);
        break;
      case 'put':
        response = await axios.put(endpoint, variables);
        break;
      case 'delete':
        response = await axios.delete(endpoint, { data: variables });
        break;
      default:
        throw new Error(`Unsupported HTTP method: ${method}`);
    }
    return response.data;
  };

  return useMutation<AxiosResponse<TData>, TError, TVariables>(mutationFn, options);
};








export { useMyGeneralQuery, useMyGeneralMutation };
