import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useMyGeneralQuery } from '../api';

export type UserInfo = {
    userId: string,
    name: string, 
    email: string,
    picture: string,
    isAnonymous: boolean
}

interface UserContextType {
  userInfo: UserInfo | null;
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfo | null>>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { data: userInfo } = useMyGeneralQuery<UserInfo>('user/info', "user/info");
  const [userInfoState, setUserInfo] = useState<UserInfo | null>(null);

  useEffect(() => {
    if (userInfo) {
      setUserInfo(userInfo);
    }
  }, [userInfo]);

  return (
    <UserContext.Provider value={{ userInfo: userInfoState, setUserInfo }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
