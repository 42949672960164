import { useNavigate, useParams } from "react-router-dom";
import { MyCard, MyNavbar, MyOuterContainer, MySpace } from "../MyDesign"
import { useTheme } from "../contexts/ThemeContext"
import IndianaUniveristyLogo from './Indiana_University_Bloomington.jpg';
import { useImageQuery, useMyGeneralQuery } from "../api";
import logger from "../logger";
import { FaLink } from "react-icons/fa6";
import { Image } from "antd-mobile";
import { format } from "date-fns";



export type UniversityProfile = {
    rank?: number;
    universityName?: string;
    country?: string;
    size?: 'L' | 'XL' | 'S' | 'M';
    focus?: 'FC' | 'CO' | 'FO' | 'SP';
    researchIntensity?: 'VH' | 'MD' | 'HI' | 'LO';
    age?: 5 | 3 | 4 | 1 | 2;
    status?: 'A' | 'B' | 'C';
    universityNameAr?: string;
    countryCode?: string;
    logoFilename?: string;
    summaryAr?: string;
    admissionRequirementLink?: string;
    minimumIeltsScore?: number;
    minimumToeflScore?: number;
    satRequirement?: 
        'optional' | 
        'not required' | 
        'considered but not required' | 
        'required' | 
        'neither required nor recommended' | 
        'recommended';
    deadlines?: Date[] | 'rolling admissions';
    applicationFee?: number;
    numberOfRecommendationLetters?: 2 | 1 | 0 | 3;
  };
  



const UniversityProfileHeader = ({universityProfile} : {universityProfile?: UniversityProfile}) => {

    const {data} = useImageQuery(`/university-profiles/public/university-logos/${universityProfile?.logoFilename}`, `/university-profiles/public/university-logos/${universityProfile?.logoFilename}`)
    const imageUrl = data ? URL.createObjectURL(data) : ''


    // console.debug(univeristyProfileLogo)

    const {theme} = useTheme()
    return(
            <div style={{display:'flex', flexDirection: 'row', direction: 'rtl', gap: '30px', alignItems: 'center'}}>
                <img src={imageUrl} alt="Description of the image" width="70" style={{borderRadius: '14px'}}  />
                <div style={{fontSize: theme.fontSizes.h3 }}>{universityProfile?.universityNameAr}</div>
            </div>
    )
}


export const Label = ({labelKey, labelValue } : any) => {
    const {theme} = useTheme()
    return(
        <div style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{
                color: theme.colors.textSecondary, 
                
                fontSize: '10px', 
                marginBottom: '3px',
                 whiteSpace: 'nowrap',        // Prevents text from wrapping
                overflow: 'hidden',          // Hides overflowed text
                textOverflow: 'ellipsis',    // Adds ellipsis (...) for overflowed text
                width: "100%",               // Ensure it takes full width
                textAlign: 'center'          // Center-align the text
            }}>
                {labelKey}
            </div>
            <div style={{
                    width: "100%",
                    height: '30px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: theme.colors.primary,
                    borderRadius: '15px',
                    color: theme.colors.background,
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.20)',
                    maxWidth: '100%',            // Set a maximum width for the container
                }}>
        
                        {labelValue}


            </div>
        </div>
    )
}

const UniversityRequirements = ({universityProfile} : {universityProfile?: UniversityProfile}) => {
    return(
        <MyCard  title={<div style={{display: 'flex', flexDirection: 'row', gap: '5px'}}><div>متطلبات القبول</div>  <FaLink color="lightblue" /> </div>} 
            onHeaderClick={() => {if(universityProfile) window.location.href = universityProfile.admissionRequirementLink ?? "" }}
        
            >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center', gridRow: 2 }}>
                <div style={{ display: 'flex',maxWidth: '500px', width: '100%', flexDirection: 'row-reverse', justifyContent: 'center', gap: '10%' }}>
                    <Label labelKey={"ايلتس"} labelValue={universityProfile?.minimumIeltsScore} />
                    <Label labelKey={"توفل"} labelValue={universityProfile?.minimumToeflScore} />
                    <Label labelKey={"سات"} labelValue={universityProfile?.satRequirement} />
                </div>
                <div style={{ display: 'flex',maxWidth: '500px', width: '100%', flexDirection: 'row-reverse', justifyContent: 'center', gap: '10%' }}>
                    <Label labelKey={"عدد التوصيات"} labelValue={universityProfile?.numberOfRecommendationLetters} />
                    <Label labelKey={"الرسوم"} labelValue={universityProfile?.applicationFee} />
                    <Label 
                            labelKey={"نهاية التقديم"} 
                            labelValue={
                                universityProfile?.deadlines === 'rolling admissions' 
                                    ? 'القبول المتداول' 
                                    : Array.isArray(universityProfile?.deadlines) && universityProfile.deadlines.length > 0 
                                        ? format(universityProfile.deadlines[0], 'MMMM dd, yyyy') 
                                        : 'غير محدد'
                            } 
                        />



                </div>
                <MySpace />
            </div>
        </MyCard>
    )
}


const UniversitySummary = ({univeristyProfile} : {univeristyProfile?: UniversityProfile}) => {
    return(
        <MyCard title="خلاصة" >
            <div>
                {univeristyProfile?.summaryAr}
            </div>
            <MySpace />
        </MyCard>
    )
}
export const UniversityProfileScreen = () => {
    const { universityNameAr } = useParams<{ universityNameAr?: string }>();

    const {data: universityProfile} = useMyGeneralQuery<UniversityProfile>([`/university-profiles/university-profile/${universityNameAr}`], `/university-profiles/university-profile/${universityNameAr}`)



    return (
        <>
            <MyNavbar backArrow={true} leftTitle={universityProfile?.universityNameAr}  />
            <div style={{paddingLeft: '10px', paddingRight: '10px',display: 'flex', flexDirection: 'column', gap: '30px'}}>
                <UniversityProfileHeader universityProfile={universityProfile}/>
                <UniversityRequirements  universityProfile={universityProfile}/>
                <UniversitySummary univeristyProfile={universityProfile} />
            </div>
        </>
    )
}