import { Card, Form, Input, Space, Button, Popover, NavBar, FloatingBubble, Selector, Divider } from "antd-mobile";
import React, { CSSProperties, ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import { randomColor } from "./App";
import { FaEllipsisVertical, FaSistrix, FaAngleLeft  } from "react-icons/fa6";
import { Action } from 'antd-mobile/es/components/popover'
import { useNavigate } from "react-router";
import { useTheme } from "./contexts/ThemeContext";
import ReactGA from 'react-ga4';


  
const MyCard: React.FC<React.ComponentProps<typeof Card>> = ({ children, style, ...restProps }) => {
  const {theme} = useTheme()
    return (
        <Card  style={{  borderRadius: '20px', backgroundColor:theme.colors.surface,
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.20)',
          ...style }} {...restProps}>
          {children}
        </Card>
    );
};

const MyButton: React.FC<React.ComponentProps<typeof Button>> = ({ children, style, ...restProps }) => {
  return (
      <Button style={{  borderRadius: '20px', width: "100%", ...style }} {...restProps}>
        {children}
      </Button>
  );
};

export const MySelector: React.FC<React.ComponentProps<typeof Selector>> = ({  style, ...restProps }) => {
  return (
    <Selector
      style={{
          '--border-radius': '100px',
          '--border': 'solid transparent 1px',
          '--checked-border': 'solid var(--adm-color-primary) 1px',
          '--padding': '8px 24px',
          ... style
        }}
      {...restProps}
      />
  );
}

export const MyForm: React.FC<React.ComponentProps<typeof Form>>= ({ children, style, ...restProps }) => {
  
  return (
    <Form
      style={{ '--border-inner': 'none', '--border-top': 'none', '--border-bottom': 'none',
            paddingLeft: '15px', paddingRight: '15px', ...style}}
      {...restProps}>
        {children}
    </Form>
  )
}



export const MyOuterContainer= ({children} : {children: any}) => {
  return (
    <div style={{paddingLeft: '15px', paddingRight: '15px'}}>
      {children}
    </div>
  )
}

const MyFormItem: React.FC<React.ComponentProps<typeof Form.Item>>= ({ children, style, ...restProps }) => {
  const {theme} = useTheme()
    return (
      <Form.Item  style={{ backgroundColor:theme.colors.surface, direction: 'ltr', ...style }} {...restProps}>
        {children}
      </Form.Item>
    );
  };



const MyInput: React.FC<React.ComponentProps<typeof Input>>= ({  style, ...restProps }) => {
  
  return (
    <Input style={{...style}} {...restProps}/>
  );
};
  
const MySpace: React.FC<React.ComponentProps<typeof Space>> = ({ children, style, ...restProps }) => {
  return (
    <Space style={{"--gap-vertical": '15px',  backgroundColor: 'black', width: '100%' , ...style}} {...restProps}>
      {children}
    </Space>
  );
};

export const MyDivider: React.FC<React.ComponentProps<typeof Divider>> = ({ children, style, ...restProps }) => {
  const {theme} = useTheme()
  return (
    <Divider direction='horizontal' style={{margin: '0px', ...style}} {...restProps}/>

  );
  
}

const MyFloatingBubble: React.FC<React.ComponentProps<typeof FloatingBubble>> = ({ children, style, ...restProps }) => {
  

  const {theme} = useTheme()
  return (
    <FloatingBubble
            style={{
            '--initial-position-bottom': '80px',
            '--initial-position-right': '24px',
            '--edge-distance': '24px',
            // '--background' :theme.colors.secondary, 
            ...style
            }}
            
            {...restProps}
        >
          {children}
      </FloatingBubble>
  );
};



const MyMorePopover= ({actions} : {actions: Action[] }) => {
  return(
    <Popover.Menu
          mode='dark'
          actions={actions}
          trigger='click'
          destroyOnHide={true}
        >
          <FaEllipsisVertical />
    </Popover.Menu>
  )
}


const MyNavbar = ({backArrow, leftTitle, costumLeft, costumRight, navigateTo, actions, style} : 
  {backArrow?: boolean, leftTitle?: string, costumLeft?: any, costumRight?: any, navigateTo?: any, actions?: any
  style?: CSSProperties
  }
  ) => {
  const navigate = useNavigate()
  const right = ( costumRight? costumRight :
      <div style={{ fontSize: 24}}>
      <Space style={{ '--gap': '16px' }}>
          { navigateTo && <FaSistrix onClick={() => navigate(navigateTo) }/> }
          { actions && <MyMorePopover actions={actions} /> }  
      </Space>
      </div>
  )
  const left = ( costumLeft? costumLeft :
      <div style={{ fontSize: 20 }}>
        { leftTitle }
      </div>
  )
  return(
      <>
          <div style={{ display: 'flex', justifyContent: 'center', position: 'sticky',top: '0', zIndex: 2 }}>
              <NavBar  
                onBack={() => navigate(-1)} 
                backArrow={backArrow ? <FaAngleLeft /> : <></>} 
                left={left} 
                right={right} 
                style={{
                  position: 'sticky', 
                  top: '0',   
                  width: '100%', 
                  height: "50px", 
                  zIndex: 2, 
                  backgroundColor: "black",
                  marginBottom: "20px", 
                  direction: 'ltr',  
                  ...style
                }}
              />
          </div>

      </>
  )
}



export {MyCard, MyButton, MyFormItem, MyInput, MySpace, MyMorePopover, MyNavbar, MyFloatingBubble}

  
