import { CenterPopup, DatePicker, DatePickerRef, Form, Input, Popup, Stepper, Switch, TextArea } from "antd-mobile"
import { MyCard, MyForm, MyFormItem, MyNavbar, MySpace } from "../MyDesign"
import { LogoutButton } from "./Logout"
import { useMyGeneralMutation, useMyGeneralQuery } from "../api"
import { RefObject, useEffect, useState } from "react"
import { UserInfo } from "../contexts/UserContext"
import { useTheme } from "../contexts/ThemeContext"

const AUTH_MODE = process.env.REACT_APP_AUTH_MODE as string;


const AnonymousPosting = () => {
    const {data: userInfo, isLoading} = 
        useMyGeneralQuery<UserInfo>('/user/info', "user/info")
    const [checked, setChecked] = useState(userInfo?.isAnonymous)

    useEffect( () => {
        setChecked(userInfo?.isAnonymous)
    }, [userInfo])

    const mutation = useMyGeneralMutation("/user/isAnonymous", 'put')
    const onChange = async (checked: boolean) => { 
        setChecked(checked)
        await mutation.mutateAsync({isAnonymous: checked}) 
    }
    

    return (
        <MyFormItem
            label='ارسل النتائج بشكل مجهول'
            childElementPosition='right'
            >
            <Switch checked={checked} onChange={onChange} loading={isLoading}/>

        </MyFormItem>
    )
}

const PrivacyGroup = () => {
    return (
        <MyCard >
            <AnonymousPosting />
        </MyCard>
    )
}



const PrivacePolicy = () => {
    const [visible, setVisible] = useState(false)
    const {theme} = useTheme()
    const styles = {
        heading: {
            margin: '20px 0', // تباعد بين العناوين والمحتوى
        },
        paragraph: {
            marginBottom: '20px', // تباعد بين الفقرات
        },
        list: {
            paddingLeft: '20px', // تباعد داخلي للقوائم
            marginBottom: '20px', // تباعد بين القوائم والفقرات
        },
        listItem: {
            marginBottom: '10px', // تباعد بين عناصر القائمة
        },
        email: {
            color: 'blue', // تغيير لون النص لتفادي اللون الافتراضي
        }
    };

    return(
        <MyFormItem
            label='سياسة الخصوصية'
            onClick={ () => setVisible(true)}
            >
            <CenterPopup
                    visible={visible}
                    onMaskClick={() => {
                        setVisible(false)
                    }}
                    onClose={() => {
                        setVisible(false)
                    }}
                    bodyStyle={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center',
                        fontSize: theme.fontSizes.small, backgroundColor: theme.colors.surface
                    }}
                    maskStyle={{backdropFilter: 'blur(8px)'}}
            >
                <div style={{padding: '20px', lineHeight: '1.8', height: '70vh',overflowY: 'scroll', direction: 'rtl', textAlign: 'right'}}>
                    <h1 style={styles.heading}>سياسة الخصوصية لموقع مقهى الابتعاث</h1>
                    <p style={styles.paragraph}>
                        نرحب بكم في موقع "مقهى الابتعاث". نحرص على حماية خصوصيتكم وضمان أمان معلوماتكم الشخصية. توضح هذه السياسة كيفية جمع، استخدام، ومشاركة المعلومات الخاصة بكم عند استخدامكم لخدماتنا.
                    </p>

                    <h2 style={styles.heading}>1. المعلومات التي نجمعها:</h2>
                    <ul style={styles.list}>
                        <li style={styles.listItem}>
                            <strong>معلومات الحساب:</strong> عندما تقوم بإنشاء حساب على "مقهى الابتعاث"، نقوم بجمع معلومات مثل اسم المستخدم، البريد الإلكتروني، وكلمة المرور.
                        </li>
                        <li style={styles.listItem}>
                            <strong>المحتوى الذي تشاركه:</strong> يشمل ذلك المنشورات، التعليقات، والتفاعلات الأخرى التي تقوم بها على الموقع.
                        </li>
                        <li style={styles.listItem}>
                            <strong>المعلومات التقنية:</strong> نجمع معلومات عن الجهاز والمتصفح الذي تستخدمه للوصول إلى الموقع، بما في ذلك عنوان IP، نوع الجهاز، نظام التشغيل، ونوع المتصفح.
                        </li>
                        <li style={styles.listItem}>
                            <strong>معلومات الموقع:</strong> قد نجمع معلومات عن موقعك الجغرافي إذا قمت بالسماح لنا بذلك.
                        </li>
                    </ul>

                    <h2 style={styles.heading}>2. كيفية استخدام المعلومات:</h2>
                    <ul style={styles.list}>
                        <li style={styles.listItem}><strong>تحسين تجربتك:</strong> نستخدم المعلومات التي نجمعها لتحسين وتخصيص تجربتك على الموقع.</li>
                        <li style={styles.listItem}><strong>التواصل معك:</strong> قد نستخدم بريدك الإلكتروني للتواصل معك بشأن تحديثات الموقع، العروض الخاصة، أو أي معلومات أخرى تهمك.</li>
                        <li style={styles.listItem}><strong>الأمان والحماية:</strong> نستخدم المعلومات للحفاظ على أمان الموقع وحماية حقوق المستخدمين.</li>
                        <li style={styles.listItem}><strong>الإعلانات:</strong> نستخدم المعلومات لعرض إعلانات مخصصة تعتقد أنها تهمك بناءً على نشاطك واهتماماتك على الموقع.</li>
                    </ul>

                    <h2 style={styles.heading}>3. مشاركة المعلومات:</h2>
                    <ul style={styles.list}>
                        <li style={styles.listItem}><strong>مع مقدمي الخدمة:</strong> قد نشارك المعلومات مع شركات تقدم خدمات لنا، مثل استضافة الموقع، التحليل، وخدمة العملاء.</li>
                        <li style={styles.listItem}><strong>لأغراض قانونية:</strong> قد نشارك المعلومات إذا كنا ملزمين بذلك بموجب القانون، أو لحماية حقوقنا وممتلكاتنا، أو لحماية حقوق وممتلكات الآخرين.</li>
                        <li style={styles.listItem}><strong>مع الشركاء:</strong> قد نشارك المعلومات مع شركائنا لأغراض تسويقية وإعلانية.</li>
                    </ul>

                    <h2 style={styles.heading}>4. خيارات التحكم في المعلومات:</h2>
                    <ul style={styles.list}>
                        <li style={styles.listItem}><strong>إعدادات الحساب:</strong> يمكنك تعديل معلومات حسابك أو حذفها في أي وقت من خلال إعدادات الحساب.</li>
                        <li style={styles.listItem}><strong>إلغاء الاشتراك:</strong> يمكنك إلغاء الاشتراك من الرسائل البريدية في أي وقت من خلال الرابط المخصص لذلك في البريد الإلكتروني.</li>
                    </ul>

                    <h2 style={styles.heading}>5. حماية المعلومات:</h2>
                    <p style={styles.paragraph}>نحن نتخذ تدابير أمنية لحماية معلوماتكم الشخصية من الوصول غير المصرح به، الاستخدام، أو الكشف.</p>

                    <h2 style={styles.heading}>6. سياسة الخصوصية للأطفال:</h2>
                    <p style={styles.paragraph}>
                        خدماتنا غير موجهة للأطفال دون سن 13 عاماً، ولا نجمع معلومات شخصية منهم عمداً. إذا علمنا أننا جمعنا معلومات من طفل دون سن 13 عاماً، سنتخذ خطوات لحذف تلك المعلومات في أقرب وقت ممكن.
                    </p>

                    <h2 style={styles.heading}>7. التعديلات على سياسة الخصوصية:</h2>
                    <p style={styles.paragraph}>
                        قد نقوم بتحديث سياسة الخصوصية من وقت لآخر. سنقوم بإعلامكم بأي تغييرات عن طريق نشر السياسة الجديدة على هذه الصفحة. ننصحكم بمراجعة هذه السياسة بشكل دوري للبقاء على اطلاع.
                    </p>

                    <h2 style={styles.heading}>التواصل معنا:</h2>
                    <p style={styles.paragraph}>
                        إذا كانت لديكم أي أسئلة أو استفسارات حول سياسة الخصوصية، يرجى التواصل معنا عبر البريد الإلكتروني: <span style={styles.email}>[IbtaathCafe@gmail.com]</span>.
                    </p>
                </div>
            </CenterPopup>
            
        </MyFormItem>
    )
}

const ContactUs = () => {
    const [visible, setVisible] = useState(false)
    const {theme} = useTheme()
    return(
        <MyFormItem
            label='تواصل معنا'
            onClick={ () => setVisible(true)}
            >
                <CenterPopup
                    visible={visible}
                    onMaskClick={() => {
                        setVisible(false)
                    }}
                    onClose={() => {
                        setVisible(false)
                    }}
                    bodyStyle={{ height: '45vh', borderRadius: 14,
                        display: 'flex', justifyContent: 'center', alignItems: 'center',
                        fontSize: theme.fontSizes.medium, backgroundColor: theme.colors.surface
                    }}
                    maskStyle={{backdropFilter: 'blur(8px)'}}
            >
                Email: IbtaathCafe@gmail.com
            </CenterPopup>
            
        </MyFormItem>
    )
}




const AboutGradCafeGroup = () => {
    return(
        <MyCard >
            <PrivacePolicy />
            <ContactUs />
        </MyCard>
    )
}

const SettingsScreen = () => {
    return(
        <> 
        <MyNavbar backArrow={true} leftTitle='الاعدادات'  />
        <MyForm
            layout='horizontal'
            footer={ <LogoutButton /> }
        >
            <Form.Header>الامان</Form.Header>
            <MySpace direction='vertical'>
                <PrivacyGroup />
            </MySpace>
            <Form.Header>عن مقهى الابتعاث</Form.Header>
            <MySpace direction='vertical'>
                <AboutGradCafeGroup />
            </MySpace>

      </MyForm>
      </>
    )
}

export {SettingsScreen}