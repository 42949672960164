// src/LoginForm.tsx
import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { MyButton, MyCard, MySpace } from '../MyDesign';
import Rodal from 'rodal';
import { Button, Mask } from 'antd-mobile';
import { GoogleLoginButton } from 'react-social-login-buttons';
import { FcGoogle } from "react-icons/fc";
import logger from '../logger';
import logo from '../assets/ibtaath_cafe_logo.png'
import { useTheme } from '../contexts/ThemeContext';
import ReactGA from 'react-ga4';
import { useFirebaseAuth } from '../contexts/FirebaseAuthContext';
import { UserCredential } from 'firebase/auth';

const LoginScreen = () => {
  const { loginWithGoogle, currentUser } = useAuth();

  const {theme} = useTheme()
  const handleGoogleSignIn = async () => {
    try {
      const {user} = await loginWithGoogle() as UserCredential;
      ReactGA.event('user_login', {
        event_category: 'User',
        event_label: 'Login',
        user_id: user.uid,
        user_name: user.displayName 
      });
    } catch (error) {
      logger.error("Error signing in with Google:", error);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        paddingLeft: '20px',
        paddingRight: '20px',
      }}
    >
      <div
        style={{
          zIndex: 2,
          backgroundColor: theme.colors.surface,
          borderRadius: '20px',
          height: '400px',
          padding: '40px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '20px',
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{ width: '100px', height: '100px' }}
        />

        <MyButton style={{ height: '50px' }} onClick={handleGoogleSignIn}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              gap: '10px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <FcGoogle />
            <div>سجل الدخول مع قوقل</div>
          </div>
        </MyButton>
      </div>
    </div>


  )
};

export default LoginScreen;
