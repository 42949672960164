import { Avatar, NavBar, PullToRefresh, Space } from "antd-mobile"
import { LogoutButton } from "./Logout"
import { MyCard, MyMorePopover, MyNavbar, MySpace } from "../MyDesign"
import { useMyGeneralQuery } from "../api"
import { useNavigate } from "react-router"
import { Action } from 'antd-mobile/es/components/popover'
import { FaSistrix } from "react-icons/fa6"
import OneUIAppBar from "../OneUIAppBar"
import { UniversityUpdate, UniversityUpdateCards } from "../univeristy-updates/UniversityUpdates"
import { useUser } from "../contexts/UserContext"
import { useEffect } from "react"
import logger from "../logger"




const ProfilePricture = ({userInfo} : any) => {
    return(
        <MyCard>
            <Space direction="vertical" style={{"--gap-vertical" : '15px', width: "100%", alignItems: "center"}} >
                    <Avatar src={userInfo?.picture ?? ""} style={{borderRadius: 50}} />
                    <div style={{fontWeight: "bold"}}>
                        {userInfo?.name}
                    </div>
            </Space>
        </MyCard>
    )
}

const UserUniversityUpdates = ({profilePicture} : any) => {
    const {data: universityUpdatesNoPicture, isLoading, refetch} = 
        useMyGeneralQuery<UniversityUpdate[]>('user/university-updates', "user/university-updates")

    const universityUpdates = universityUpdatesNoPicture?.map(universityUpdate => { return {...universityUpdate, profile_picture: profilePicture}})
    return (
        <PullToRefresh onRefresh={refetch}>
            <UniversityUpdateCards universityUpdates={universityUpdates} isLoading={isLoading} />
        </PullToRefresh>
    )
}


const useActions = () => {
    const navigate = useNavigate()
    const actions: Action[] = [
        { key: 'settings', text: 'الاعدادات', onClick: () => navigate('settings')  },
    ]
    return actions
}

const ProfileScreen = () => {
    // const {data: userInfo} = 
    //     useMyGeneralQuery<UserInfo>('user/info', "user/info")
    const { userInfo } = useUser();



    const actions = useActions()
    return(
        // <MySpace direction="vertical" >
        <>
            <OneUIAppBar>
                <ProfilePricture userInfo={userInfo} />
            </OneUIAppBar>
            <MyNavbar  actions={actions} leftTitle={userInfo?.name}  />
            <UserUniversityUpdates profilePicture={userInfo?.picture} />
        </>
        // </MySpace>
    )
}


export {ProfileScreen}