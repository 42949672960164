import { useQuery } from "react-query";
import { BASE_URL, randomColor } from "../App";
import { MyButton, MyCard, MyMorePopover, MySpace } from "../MyDesign";
import { Divider, FloatingBubble, Space, Mask, Button, Input } from "antd-mobile";
import { FaPencil, FaAngleRight, FaAngleLeft, FaMagnifyingGlass, FaEllipsisVertical } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { formatDistance } from "date-fns";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { UniversityUpdate, UniversityUpdateCards } from "./UniversityUpdates";
import { useMyGeneralMutation, useMyGeneralQuery } from "../api";
import logger from "../logger";
import { useTheme } from "../contexts/ThemeContext";
import { getValue } from "@testing-library/user-event/dist/utils";
import MySearchBar from "../shared-components/SearchBar";




const UniversityUpdateSearchScreen = () => {
    const searchEndpoint = "/university-updates/search"
    const [searchText, setSearchText] = useState(() => {
        // Retrieve the search text from local storage on initial mount
        return localStorage.getItem(searchEndpoint) || "";
    });
    useEffect(() => {
        localStorage.setItem(searchEndpoint, searchText);
    }, [searchText]);

    const {data: universityUpdates,} = 
        useMyGeneralQuery<UniversityUpdate[]>([searchEndpoint, searchText], searchEndpoint, { search: searchText},
        {
            enabled: searchText !== "", // Only enable the query if searchText is not an empty string
        }
        )
    return( 
        <>  
            <MySearchBar searchText={searchText} setSearchText={setSearchText} searchHistoryEndpoint="/university-updates/search-history" />
            <UniversityUpdateCards universityUpdates={universityUpdates} />
        </>
    )
}

export {UniversityUpdateSearchScreen}